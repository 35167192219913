import { AtomAccordionLink } from 'atoms/AtomLink'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { Colors } from 'commons/Theme'
import { processCategorieStructure } from 'commons/utils'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { ParagraphDoorwaysBannerFe } from 'components/DynamicComponent/map/ParagraphDoorwaysBannerFe'
import { CaretDownIcon } from 'components/Icons/CaretDownIcon'
import { Icon } from 'components/Icons/Icon'
import Modal from 'components/Modale/Modal'
import { graphql } from 'gatsby'
import { CategoriesTutelaDelClienteType } from 'models/categories-structure-model'
import {
  StyledArgomentiTitle,
  StyledArgomentiWrapper,
  StyledArgomentoChildrenWrapper,
  StyledArgomentoItem,
  StyledArgomentoLink,
  StyledArgumentChild,
  StyledBannerWrapper,
  StyledCategoriaWrapper,
  StyledChildrenWrapper,
  StyledContentWrapper,
  StyledDesktopMenu,
  StyledMobileMenu,
  StyledMobileMenuButton,
  StyledTitleWrapper,
} from 'pages-style/categoria.style'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Grid } from '../commons/Grid'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import Layout from '../Layout'
import { isParagraphTestoLibero } from 'components/DynamicComponent/map/ParagraphTestoLiberoFe'
import classNames from 'classnames'
import { HeroImage } from 'components/HeroImage/HeroImage'
import Link from 'components/Link/Link'

function MobileMenuButton({
  label,
  onClick,
}: {
  label: string
  onClick: () => void
}) {
  return (
    <StyledMobileMenuButton onClick={onClick}>
      {label}
      <Icon Icon={CaretDownIcon} stroke={Colors.azure600} width="24px" />
    </StyledMobileMenuButton>
  )
}

export default function PageDefaultCategorieTutelaDelCliente(
  props: PageCategorieDataProps
) {
  const {
    data: { page, categories },
    pageContext: { title },
  } = props
  const allCategoryPages =
    processCategorieStructure<CategoriesTutelaDelClienteType>(categories.nodes)
  const currentPage = allCategoryPages.find((item) => item.id === page.id)
  const pageMenu = processCategorieStructure<CategoriesTutelaDelClienteType>(
    categories.nodes,
    true
  )
  const parent = page.relationships.parent?.[0]?.id || null

  const [showModal, setShowModal] = useState<boolean>(false)

  const currentId = page.id
  const isChild = !!parent
  const parentsMenu: MenuLinkType[] = pageMenu.map((x) => ({
    name: x.data.name,
    url: x.data.path.alias,
    id: x.id,
  }))
  const siblingsMenu =
    isChild &&
    pageMenu
      .find((x) => x.id === parent)
      ?.children.map((x) => ({
        name: x.data.name,
        url: x.data.path.alias,
        id: x.id,
      }))
  const pageTitle =
    page.relationships.field_dettaglio_tassonomia &&
    page.relationships.field_dettaglio_tassonomia[0] &&
    isParagraphTestoLibero(page.relationships.field_dettaglio_tassonomia[0]) &&
    page.relationships.field_dettaglio_tassonomia[0].field_testo_libero &&
    page.relationships.field_dettaglio_tassonomia[0].field_testo_libero
      .processed
  const hasChildren = !!currentPage?.children.length

  function MenuItems() {
    return (
      <>
        <StyledArgomentiTitle>Tutti gli argomenti</StyledArgomentiTitle>
        <StyledArgomentiWrapper>
          {parentsMenu.map((arg: MenuLinkType) => (
            <StyledArgomentoItem key={arg.id}>
              <StyledArgomentoLink
                to={arg.url}
                isActive={arg.id === currentId || arg.id === parent}
              >
                {arg.name}
              </StyledArgomentoLink>
              {isChild && arg.id === parent && siblingsMenu && (
                <StyledArgomentoChildrenWrapper>
                  {siblingsMenu.map((argChild: MenuLinkType) => (
                    <StyledArgumentChild key={argChild.id}>
                      <Link to={argChild.url}>{argChild.name}</Link>
                    </StyledArgumentChild>
                  ))}
                </StyledArgomentoChildrenWrapper>
              )}
            </StyledArgomentoItem>
          ))}
        </StyledArgomentiWrapper>
      </>
    )
  }

  function MobileMenu() {
    return (
      <StyledMobileMenu>
        <MobileMenuButton label={title} onClick={() => setShowModal(true)} />
        <MobileMenuModal />
      </StyledMobileMenu>
    )
  }

  function DesktopMenu() {
    return (
      <StyledDesktopMenu>
        <MenuItems />
      </StyledDesktopMenu>
    )
  }

  const MobileMenuModal = () => {
    return (
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        fullScreen={true}
      >
        <MenuItems />
      </Modal>
    )
  }

  return (
    <A2ADataContextProvider>
      <Layout pageTitle={page.field_meta_tags_taxonomy_fe?.title || title}>
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-categorie-tutela-del-cliente',
              'a2a-navigation-main',
              'a2a-navigation-full'
            ),
          }}
        >
          {page.field_meta_tags_taxonomy_fe?.description && (
            <meta
              name="description"
              content={page.field_meta_tags_taxonomy_fe.description}
            />
          )}
        </Helmet>

        <CanonicalUrlComponent url={page?.path?.alias} />

        <HeroImage
          backgroundColor="blue"
          title={page.name}
          breadcrumbLinks={
            page.relationships?.field_breadcrumb_content?.field_link_breadcrumb
              ? [
                  ...page.relationships.field_breadcrumb_content
                    .field_link_breadcrumb,
                  { title: page.name, uri: page.path.alias },
                ]
              : undefined
          }
        />
        <StyledCategoriaWrapper>
          <Grid
            cols={1}
            desktopCols="1fr 2fr"
            colsDesktopGap="28px"
            rowsGap="40px"
          >
            <DesktopMenu />
            <MobileMenu />
            <div>
              {hasChildren && (
                <>
                  <StyledTitleWrapper
                    dangerouslySetInnerHTML={{ __html: pageTitle || title }}
                  />
                  <StyledChildrenWrapper>
                    {currentPage.children.map((child, i) => (
                      <AtomAccordionLink
                        key={i}
                        label={child.data.name}
                        to={child.data.path.alias}
                      />
                    ))}
                  </StyledChildrenWrapper>
                </>
              )}
              {!hasChildren &&
                page.relationships.field_dettaglio_tassonomia &&
                page.relationships.field_dettaglio_tassonomia.length > 0 && (
                  <StyledContentWrapper>
                    {page.relationships.field_dettaglio_tassonomia.map(
                      (child, i) => (
                        <DynamicComponent
                          key={i}
                          component={child.__typename}
                          data={child}
                          related={{
                            parent: {
                              __typename: page.__typename,
                            },
                            siblings: {
                              before:
                                page.relationships.field_dettaglio_tassonomia?.[
                                  i - 1
                                ] ?? undefined,
                              after:
                                page.relationships.field_dettaglio_tassonomia?.[
                                  i + 1
                                ] ?? undefined,
                            },
                          }}
                        />
                      )
                    )}
                  </StyledContentWrapper>
                )}
            </div>
          </Grid>
        </StyledCategoriaWrapper>
        {page.relationships.field_doorways_banner &&
          typeof page.relationships.field_doorways_banner === 'object' &&
          !!page.relationships.field_doorways_banner.length &&
          !!page.relationships.field_doorways_banner[0] && (
            <StyledBannerWrapper>
              <DynamicComponent
                component={
                  page.relationships.field_doorways_banner[0].__typename
                }
                data={page.relationships.field_doorways_banner[0]}
                related={{
                  parent: undefined,
                }}
              />
            </StyledBannerWrapper>
          )}
      </Layout>
    </A2ADataContextProvider>
  )
}

interface PageDataItemType {
  __typename: string
  id: string
  drupal_id: string
  internal: {
    type: string
  }
  name: string
  path: {
    alias: string
  }
  field_meta_tags_taxonomy_fe?: {
    description: string | null
    title: string | null
  } | null
  field_domande_sottotitolo: {
    format: string
    processed: string
  }
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_doorways_banner?: ParagraphDoorwaysBannerFe[]
    field_dettaglio_tassonomia?: ComponentDataModel[] | null
    parent:
      | {
          id: string
          drupal_id: string
          name: string
          path: {
            alias: string
          }
        }[]
      | null
  }
}

interface PageCategorieDataProps {
  data: {
    page: PageDataItemType
    categories: {
      nodes: CategoriesTutelaDelClienteType[]
    }
  }
  pageContext: {
    id: string
    title: string
  }
}

type MenuLinkType = {
  name: string
  url: string
  id: string
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: taxonomyTermTutelaDelClienteFe(id: { eq: $id }) {
      __typename
      id
      drupal_id
      internal {
        type
      }
      name
      path {
        alias
      }
      field_meta_tags_taxonomy_fe {
        description
        title
      }
      field_domande_sottotitolo {
        format
        processed
      }
      relationships {
        field_breadcrumb_content {
          ...BreadcrumbsFe
        }
        field_doorways_banner {
          ...ParagraphDoorwaysBannerFe
        }
        field_dettaglio_tassonomia {
          __typename
          ...ParagraphAccordionFe
          ...ParagraphDocumentiFe
          ...ParagraphMarginiFe
          ...ParagraphTabsFe
          ...ParagraphTestoLiberoFe
        }
        parent {
          id
          drupal_id
          name
          path {
            alias
          }
        }
      }
    }
    categories: allTaxonomyTermTutelaDelClienteFe(
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        __typename
        id
        drupal_id
        internal {
          type
        }
        name
        path {
          alias
        }
        relationships {
          parent {
            id
            drupal_id
            name
            path {
              alias
            }
          }
        }
      }
    }
  }
`
