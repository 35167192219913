import { ContainerFluid } from 'commons/Container'
import {
  BPSizes,
  Palette,
  SiteSpacings,
  StyledHTMLTags,
  StyledHeadingH3,
  Typography,
} from 'commons/Theme'
import Link from 'components/Link/Link'
import styled from 'styled-components'

export const StyledArgomentoLink = styled(Link)<{ isActive?: boolean }>`
  ${Typography.BodyRegular.sm};
  color: ${({ isActive }) =>
    isActive ? Palette.Azure[600] : Palette.Blue[800]};
  transition: all 0s;
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};

  &:hover {
    color: ${Palette.Azure[600]};
    font-weight: 600;
    text-decoration: none;
  }
`

export const StyledCategoriaWrapper = styled(ContainerFluid)`
  margin-top: ${SiteSpacings.Spacing07.lg}px;
  margin-bottom: ${SiteSpacings.Spacing07.lg}px;
  /* Disabled as per new Layout - CRQA2A-3782 */
  /* max-width: 1440px; */
  @media screen and (min-width: ${BPSizes.md}px) {
    margin-top: ${SiteSpacings.Spacing09.lg}px;
    margin-bottom: ${SiteSpacings.Spacing09.lg}px;
  }

  @media screen and (min-width: ${BPSizes.lg}px) {
    margin-top: ${SiteSpacings.Spacing10.lg}px;
    margin-bottom: ${SiteSpacings.Spacing10.lg}px;
  }
`

export const StyledArgomentiWrapper = styled.ul`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const StyledArgomentoItem = styled.li`
  display: block;
  padding: ${SiteSpacings.Spacing05.sm}px 0;
  border-bottom: 1px solid ${Palette.Greyscale[500]};
`

export const StyledAccordionWrapper = styled.div`
  margin-top: 64px;
`

export const StyledArgomentiTitle = styled.div`
  ${Typography.Paragraph.max};
  color: ${Palette.Blue[800]};
  margin-top: 0;
  margin-bottom: ${SiteSpacings.Spacing06.sm}px;
`

export const StyledArgumentChild = styled.li<{ $isActive?: boolean }>`
  ${Typography.BodyRegular.sm};
  display: block;
  margin-bottom: ${SiteSpacings.Spacing04.sm}px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: block;
    color: ${({ $isActive }) =>
      $isActive ? Palette.Azure[600] : Palette.Greyscale[800]};
    transition: all 0s;
    font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};

    &:hover {
      color: ${Palette.Azure[600]};
      font-weight: 600;
      text-decoration: none;
    }
  }
`

export const StyledArgumentChildLink = styled(Link)<{ $isActive?: boolean }>`
  ${Typography.BodyRegular.sm};
  display: block;
  color: ${({ $isActive }) =>
    $isActive ? Palette.Azure[600] : Palette.Greyscale[800]};
  transition: all 0s;
  text-decoration: none;
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};

  &:hover {
    color: ${Palette.Azure[600]};
    font-weight: 600;
    text-decoration: none;
  }
`

export const StyledChildrenWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StyledArgomentoChildrenWrapper = styled.ul`
  margin-top: ${SiteSpacings.Spacing04.sm}px;
  list-style: none;
`

export const StyledBannerWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 160px;
    margin-bottom: 160px;
  }
`

export const StyledDesktopMenu = styled.div`
  display: none;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    display: block;
  }
`

export const StyledMobileMenu = styled.div`
  display: block;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    display: none;
  }
`

export const StyledMobileMenuButton = styled.button`
  display: flex;
  justify-content: space-between;
  ${Typography.NavLink01.sm};
  width: 100%;
  cursor: pointer;
  color: ${Palette.Azure[600]};
  background-color: transparent;
  border: none;
  margin: 0;
  padding: ${SiteSpacings.Spacing05.sm}px 0;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${Palette.Blue[800]};
  }
`

export const StyledSearchComponent = styled.div`
  position: relative;
  width: 100%;
  max-width: 419px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid ${Palette.Greyscale['800']};
`

export const StyledSearchComponentInput = styled.input`
  width: 100%;
  height: 56px;
  ${Typography.BodyRegular.sm};
  border: none;
  background-color: transparent;
  padding: ${SiteSpacings.Spacing03.sm}px 32px ${SiteSpacings.Spacing03.sm}px
    ${SiteSpacings.Spacing04.sm}px;

  &::placeholder {
    ${Typography.BodyRegular.sm};
    color: ${Palette.Greyscale['800']};
  }

  &:focus {
    border-color: ${Palette.Blue['600']};
  }

  &:focus-visible {
    border: 1px solid ${Palette.Blue['600']} !important;
    box-shadow: none !important;
  }
`

export const StyledSearchComponentInputIcon = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    stroke: ${Palette.Greyscale['800']};
  }
`

export const StyledTitleWrapper = styled(StyledHTMLTags)`
  margin-top: 0;

  h1,
  .h1,
  h2:not([class]),
  .title {
    ${StyledHeadingH3};
    margin-top: 0;
    margin-bottom: ${SiteSpacings.Spacing08.sm}px;

    @media screen and (min-width: ${BPSizes.md}px) {
      margin-bottom: ${SiteSpacings.Spacing08.md}px;
    }

    @media screen and (min-width: ${BPSizes.lg}px) {
      margin-bottom: ${SiteSpacings.Spacing08.lg}px;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  .title {
    color: ${Palette.Blue['800']};
  }
`

export const StyledContentWrapper = styled.div`
  margin-top: 0;

  h1,
  .h1,
  h2:not([class]),
  .title {
    ${StyledHeadingH3};
    margin-top: 0;
    margin-bottom: ${SiteSpacings.Spacing08.sm}px;

    @media screen and (min-width: ${BPSizes.md}px) {
      margin-bottom: ${SiteSpacings.Spacing08.md}px;
    }

    @media screen and (min-width: ${BPSizes.lg}px) {
      margin-bottom: ${SiteSpacings.Spacing08.lg}px;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  .title {
    color: ${Palette.Blue['800']};
  }
`
