import { Helmet } from 'react-helmet'
import { removeTrailingSlashes } from './utils'

export const CanonicalUrlComponent = (props: PageCanonicalUrlModel) => {
  const { url } = props

  if (!url) {
    return null
  }

  return (
    <Helmet>
      <link
        rel="canonical"
        href={`${removeTrailingSlashes(process.env.SITE_URL)}${
          url.indexOf('homepage') === -1 ? url : ''
        }`}
      />
    </Helmet>
  )
}

interface PageCanonicalUrlModel {
  url: string | null | undefined
}
